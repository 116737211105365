<template>
	<div>
		<p>운영에 필요한 데이터를 다운로드 받으세요.</p>
		<searchCard :reset="reset">
			<h6>기간 선택</h6>
			<div class="flex">
				<datePicker class="" model="input.startDate" />
				<span class="p-2 lh-6">~</span>
				<datePicker model="input.endDate" />
			</div>
		</searchCard>
		<b-card class="p-4 z-10 mt-6">
			<b-button
				v-for="v in [
					{ title: '운영', api: 'dealSelect/downloadDealInfo', variant: '' },
					{
						title: '앱푸시',
						api: 'api/backoffice/pushNotificationSelect/downloadPushNotificationHistoryData',
						variant: 'info',
					},
					{
						title: '판매',
						api: 'api/backoffice/orderSelect/downloadSalesData',
						variant: 'warning',
					},
				]"
				class="mr-4"
				@click="downloadExcelInfo(v.api, v.title)"
				:variant="v.variant"
				v-text="v.title + ' 데이터 다운로드'"
			/>
		</b-card>
	</div>
</template>

<script>
const now = new Date(),
	defInput = {
		startDate: getMonth(now, -1),
		endDate: now,
	}

export default {
	data() {
		return {
			input: { ...defInput },
		}
	},
	methods: {
		reset() {
			this.input = { ...defInput }
		},
		downloadExcelInfo(url, dataKind) {
			const input = this.input,
				ex = new excel(dataKind + ' 데이터 다운로드')

			ex.date(input.startDate, input.endDate, '기간 선택')
			postApi(url, {
				startDate: input.startDate.get_time('d'),
				endDate: input.endDate.get_time('d'),
				excelInfo: { ...ex.get() },
			}).then(() => alert.excel())
		},
	},
	created() {},

	computed: {},
}
</script>
